<template>
  <a-spin :spinning="isLoading">
    <div class="accountContainer" v-if="route === 'accountMenu'">
      <div class="gutter-all" v-for="item in this.dataTree" :key="item.dataIndex">
        <template v-if="item.children.length > 0 && item.checked">
          <span class="title">{{ item.title }}</span>
          <template v-for="child in item.children">
            <router-link v-if="!child.hidden" :to="{ name: `${child.router}` }" class="gutter-row" :key="child.name">
              <template v-if="child.checked">
                <img :src="child.icon ? child.icon : '/images/app-none.png'" alt="" />
                <div class="con-title">{{ child.name }}</div>
                <span class="con-desc">{{ child.desc }}</span>
              </template>
            </router-link>
          </template>
        </template>
      </div>
    </div>
    <template v-else>
      <div class="title-boxs" v-if="route !=='addmaskrule' && route !=='updatemaskrule'">
        <div v-if="route === 'addWorkOrder'" class="back" @click="goBack">←返回</div>
        <div class="title-box">
          <!-- 超管号同时出现两个tab -->
          <template v-if="isSuperAdmin && isOriginality">
            <router-link
              class="link-to"
              :to="{ name: 'originality' }"
            >先审后投
              <p :class="{ isactive: currentRouteName === '先审后投' }"></p
              ></router-link>
            <router-link
              class="link-to"
              :to="{ name: 'beforeReview' }"
            >先投后审
              <p :class="{ isactive: currentRouteName === '先投后审' }"></p
              ></router-link>
          </template>
          <span
            v-else
          >{{ title }}
            <p class="isactive"></p
            ></span>
        </div>
      </div>
      <route-view></route-view>
    </template>
  </a-spin>
</template>
<script>
import { RouteView } from '@/layouts'
import { mapState } from 'vuex'
import { menuList } from './menuList'
export default {
  name: 'AccountMenu',
  components: { RouteView },
  data () {
    return {
      columns: menuList,
      isAccountOrPassword: 'account',
      route: '',
      title: '',
      dataTree: [],
      current: 0
    }
  },
  computed: {
    ...mapState({
      // 权限路由集合
      allRoutes: (state) => state.permission.allRoutes,
      auditType: (state) => state.user.auditType,
      isSuperAdmin: (state) => state.user.roles && state.user.roles[0] === 'SUPER_ADMIN',
      origin: (state) => state.autoweb.origin
    }),
    currentRouteName () {
      return this.$route.meta.title
    },
    isOriginality () {
      return this.$route.meta.title === '先审后投' || this.$route.meta.title === '先投后审'
    }
  },
  created () {
    this.getColumns()
  },
  watch: {
    $route: {
      handler (val) {
        this.route = val.name
        if (val.name === 'originality') {
          this.title = this.auditType === 0 ? '先投后审' : '先审后投'
        } else {
          this.columns.forEach((item) => {
            item.children.forEach((child) => {
              if (val.name === child.routeName) {
                this.title = child.name
              }
            })
          })
        }
      },
      immediate: true,
      deep: true
    },
    auditType: {
      handler (val) {
        if (this.$route.name === 'originality') {
          this.title = +val === 0 ? '先投后审' : '先审后投'
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    getColumns () {
      const arr = JSON.parse(JSON.stringify(this.columns))
      this.isLoading = true
      arr.map((item) => {
        this.allRoutes.map((child) => {
          item.children.map((item2, i) => {
            if (child === item2.router) {
              item.checked = true
              item2.checked = true
            }
            if (this.origin !== 'https://app.mediatom.cn' && (item2.id === 14 || item2.id === 15)) {
              item2.checked = false
            }
          })
        })
        item.children = item.children.filter((data) => {
          return data.checked
        })
      })
      this.dataTree = arr
      this.isLoading = false
    },
    goBack () {
      this.$router.back()
    }
  }
}
</script>
<style lang="less" scoped>
.page-header-wrapper-grid-content-main {
  height: 100%;
  min-height: 100%;
  transition: 0.3s;
}
::v-deep.ant-radio-button-wrapper-checked {
  color: #456bff;
  background: fade(@primary-color, 15%);
  border: 1px solid #456bff;
}
.left-radius {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.right-radius {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.accountContainer {
  margin: 10px;
  background: #fff;
  display: inline-block;
  padding: 0px 5% 8% 5%;
  width: calc(100% - 20px);
  border-radius: 10px;
}
.gutter-example :deep(.ant-row > div) {
  background: transparent;
  border: 0;
}
.gutter-all {
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
  .title {
    width: 100%;
    text-align: left;
    font-size: 16px;
    color: #333333;
    margin-bottom: 20px;
  }
  .gutter-row {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: 170px;
    width: 23%;
    background: #ffffff;
    border: 1px solid #e6e6e6;
    border-radius: 10px;
    margin: 0 20px 20px 0;
    padding: 20px 20px 30px 20px;
    font-size: 14px;
    font-weight: 400;

    img {
      width: 40px;
      height: 40px;
    }
    span {
      color: #999999;
    }
    div {
      color: #333333;
      margin: 20px 0 14px;
    }
  }
}
.title-boxs {
  width: 100%;
  padding-left: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #fff;
  margin-bottom: 10px;
  .back {
    border-right: 2px solid #d8d8d8;
    height: 25px;
    line-height: 25px;
    padding-right: 20px;
    color: #4d4d4d;
    cursor: pointer;
  }
}
.title-box {
  height: 46px;
  line-height: 46px;
  font-size: 14px;
  color: #333333;
  color: @primary-color;
  span,
  .link-to {
    position: relative;
    padding: 0 20px;
    cursor: pointer;
    p {
      display: none;
      &.isactive {
        display: inline-block;
        width: 50%;
        position: absolute;
        height: 3px;
        background-color: @primary-color;
        left: 25%;
        bottom: -30px;
      }
    }
  }
}
</style>
